import React, { useState, useEffect } from 'react';
import { compose } from "modules/core";
import PocReprtListView from "modules/poc-dashboard/components/poc_report/pocReportListView";
import { withApollo } from '@apollo/client/react/hoc';
import { ORG_POC_SURVEYS, ORG_POC_SURVEY_RESPONSE_SET } from "modules/poc-dashboard/graphql/pocDashboardQuery.gql";
import { VALUE_SUERVEY_PART_1, PERSONALITY_PART_1, STRENGTH_PART_1, KNOWLEDGE_PART_1, grow_survey_id, SURVEY_READINESS_LEVEL, feedback_survey_id, feedback_1_1_survey_id, USER_MANUAL_SURVEY_ID, effectiveness_indicator_one_on_one, SURVEY_DIVERSITY_PROFILE, SURVEY_ID_3CS } from '../../../../config';
import { getIntFromString, globalPermissionValidator } from 'modules/look';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import { poc_admin_permission } from 'Permissions/poc_admin.permission';
import { Spin } from 'antd';

const PocReportListContainer = (props) => {
    const { me, client, userPermission } = props;
    const [org_survey_lists, setSurveyList] = useState([]);
    const [selected_survey_response, setSurveyResponseSet] = useState();
    const [table_data_loading, setTableDataLoading] = useState(true);
    const [total_score, setTotalScore] = useState()
    const [response_page_info, setReasponsePageInfo] = useState()
    const response_data_ref = React.useRef([])
    const [permission, setPermission] = React.useState()
    React.useEffect(() => {
        response_data_ref.current = selected_survey_response
    }, [selected_survey_response])

    React.useEffect(() => {
        if (userPermission?.length && me) {
            let permission = globalPermissionValidator(poc_admin_permission, userPermission)

            if (permission.required_permission && !me?.employee?.orgPocEmployee?.totalCount) {
                permission.required_permission = false
            }
            if (permission.required_permission && !me?.employee?.organizationSet?.edges?.length) {
                permission.required_permission = false
            }
            setPermission(permission)
        }
    }, [userPermission, me])

    useEffect(() => {
        if (permission?.required_permission) {
            getOrgSurveyList();
        }
    }, [permission]);

    const getOrgSurveyList = async () => {
        const { data } = await client.query({
            query: ORG_POC_SURVEYS,
            fetchPolicy: 'network-only'
        });
        if (data?.allOrgSurveysByImplicitOrgId?.edges?.length) {
            let skip_survey = [VALUE_SUERVEY_PART_1, PERSONALITY_PART_1, STRENGTH_PART_1, KNOWLEDGE_PART_1, grow_survey_id, SURVEY_READINESS_LEVEL, feedback_survey_id, feedback_1_1_survey_id, USER_MANUAL_SURVEY_ID, effectiveness_indicator_one_on_one, SURVEY_DIVERSITY_PROFILE, SURVEY_ID_3CS]
            let list_after_skip = data?.allOrgSurveysByImplicitOrgId?.edges?.filter(({ node }) => !skip_survey.includes(getIntFromString(node?.id)))
            setSurveyList(list_after_skip?.map(({ node }) => node));
        }
    };
    const checkTotalScore = (value) => {
        let answerSet = value?.edges?.map(({ node }) => node)
        let question_ids = [...new Set(answerSet?.map(i => i?.question?.id))]
        let total_score = 0
        question_ids.forEach(q => {
            let list_answer = answerSet?.filter(a => a?.question?.id == q)
            let choice_set = list_answer[0]?.question?.choiceSet?.edges?.map(({ node }) => node)
            let user_answer = list_answer?.map(ans => ans?.answer)?.flatMap(item => item.split(','))
            let correct_answers = choice_set?.filter(cw => cw?.answerKey)?.map(i => i?.value)
            let user_input_is_correct = !correct_answers?.length ? false : correct_answers?.some(i => user_answer?.includes(i))
            if (user_input_is_correct) {
                total_score = total_score + 1
            }
        })
        return total_score
    }
    const getSelectedSurveyResponse = async (filter) => {
        setTableDataLoading(true)
        const { data } = await client.query({
            query: ORG_POC_SURVEY_RESPONSE_SET,
            variables: { ...filter },
            fetchPolicy: 'network-only'
        });
        if (data?.surveyResponses) {
            setReasponsePageInfo({
                hasNextPage: data?.surveyResponses?.pageInfo?.hasNextPage,
                cursor: data?.surveyResponses?.pageInfo?.endCursor
            })
            let list = data?.surveyResponses?.edges?.map(({ node }) => {
                return {
                    firstName: node?.user?.firstName,
                    lastName: node?.user?.lastName,
                    email: node?.user?.email,
                    response_id: node?.id,
                    responseDate: node?.responseDate,
                    totalScore: checkTotalScore(node?.answerSet)

                }
            })
            setTotalScore(data?.surveyResponses?.edges[0]?.node?.survey?.groupSet?.edges[0]?.node?.questionSet?.totalCount)
            let data_list = filter?.cursor ? response_data_ref?.current.concat(list) : list
            setSurveyResponseSet(data_list)
            setTableDataLoading(false)
        }
    }
    const sortScore = (type) => {
        setTableDataLoading(true)
        let result = [...selected_survey_response]
        if (type === 'ascending') {
            result = result.sort((a, b) => a?.totalScore - b?.totalScore);
        } else if (type === 'descending') {
            result = result.sort((a, b) => b?.totalScore - a?.totalScore);
        }
        setTableDataLoading(false)
        setSurveyResponseSet(result)
    }
    return (
        <>
            {(permission && !permission?.required_permission) && (<NoPermissionView />)}
            {
                (permission && permission?.required_permission) && (
                    <PocReprtListView org_survey_lists={org_survey_lists}
                        selected_survey_response={selected_survey_response}
                        getSelectedSurveyResponse={(filter => getSelectedSurveyResponse({ ...filter }))}
                        table_data_loading={table_data_loading}
                        sortScore={(type) => sortScore(type)}
                        total_score={total_score}
                        response_page_info={response_page_info}
                        {...props} />
                )
            }
            {
                !permission && (
                    <div className="role-no-permission-container">
                        <Spin spinning={true} size="large"></Spin>
                    </div>
                )
            }

        </>
    );
};

export default compose(withApollo)(PocReportListContainer);
