import React, { useEffect, useState } from 'react';
import { compose } from '../../core';
import GrowHomePage from '../components/GrowHomeView';
import { withApollo } from '@apollo/client/react/hoc';
import { COACHING_CONVERSATION, COACHING_CONVERSATION_SUBSCRIPTION } from '../graphql/ConversationList.gql'
import { GROW_GOAL_QUESTION_ADDRESS } from '../../../config'
import { globalPermissionValidator } from 'modules/look';
import { coaching_engagement_permission } from 'Permissions/coachingEngagement.permission';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import { withGetAllCustomUserCommitments } from 'modules/dashboard/containers/DashboardOperations';

const GrowHomeContainer = props => {
  const { client, me, history, userPermission, permissionLoader } = props
  const [allCoachingConversation, setallCoachingConversation] = React.useState()
  const [coachingPermitted, setCoachingPermitted] = React.useState()
  const [loading, setloading] = React.useState(true)
  const [grow_page_info, SetGrowPageInfo] = React.useState()
  const mountedRef = React.useRef(true)
  let coachingSub = undefined 
  const grow_list_ref = React.useRef([])
  const filter_ref = React.useRef({})

  React.useEffect(() => {
    return () => {
      if (coachingSub) {
        coachingSub.unsubscribe();
      }
    }
  })



  React.useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  React.useEffect(() => {
    mountedRef.current = true
  }, [])

  React.useEffect(() => {
    grow_list_ref.current = allCoachingConversation
  }, [allCoachingConversation])

  const coachingConversation = async (filter) => { 
    filter_ref.current = { ...filter, coach_cursor: null, coachee_cursor: null } || {}
    setloading(true)
    const { data } = await client.query({
      query: COACHING_CONVERSATION,
      fetchPolicy: 'network-only',
      variables: { ...filter, employee: me?.employee?.id, question_QuestionAddress: GROW_GOAL_QUESTION_ADDRESS, nextCoachingConversation_Isnull: true,first:10 },
    });

    if (data) {

      SetGrowPageInfo({
        hasNextPage: (data?.coach?.pageInfo?.hasNextPage || data?.coachee?.pageInfo?.hasNextPage),
        coach_cursor: data?.coach?.pageInfo?.endCursor,
        coachee_cursor: data?.coachee?.pageInfo?.endCursor,
      })
      let coaching_list = data?.coach?.edges?.map(({ node }) => {
        return {
          ...node,
          user: node?.coachee?.user?.id !== me?.employee?.user?.id ? {
            user_details: node?.coachee?.user,
            type: 'My coachee'
          } :
            {
              user_details: node?.coach?.user,
              type: 'My coach'
            }
        }
      }).concat(
        data?.coachee?.edges?.map(({ node }) => {
          return {
            ...node,
            user: node?.coachee?.user?.id !== me?.employee?.user?.id ? {
              user_details: node?.coachee?.user,
              type: 'My coachee'
            } :
              {
                user_details: node?.coach?.user,
                type: 'My coach'
              }
          }
        })
      )
      if (filter?.coach_cursor || filter?.coachee_cursor) {
        coaching_list = grow_list_ref.current.concat(coaching_list)
      }
      setallCoachingConversation(coaching_list)  
      subscribeToMore(filter)
    }
    setloading(false)
  }

  const subscribeToMore = async (filter) => {
    coachingSub = client.subscribe({
      query: COACHING_CONVERSATION_SUBSCRIPTION,
      variables: filter
    }).subscribe({
      next(result) {
        let CoacheCacheData = grow_list_ref.current
        switch (result?.data?.coachingConversationSubscription?.mutation) {
          case 'CREATE':
            const deletedIfExists = CoacheCacheData?.filter((node) =>
              node.id === result?.data?.coachingConversationSubscription?.coachingConversation?.id
                ? false
                : true).concat([{ node: result?.data?.coachingConversationSubscription?.coachingConversation }])
            if (mountedRef.current) {
              setallCoachingConversation(deletedIfExists) 
            }
            break
          case 'UPDATE':
            const updated = CoacheCacheData?.map(({ node }) =>
              node.id === result?.data?.CoachingConversationSubscription?.coachingConversation?.id
                ? { node: result?.data?.CoachingConversationSubscription?.coachingConversation }
                : node
            )
            if (mountedRef.current) {
              setallCoachingConversation(updated) 
            }
            break
          case 'DELETE':
            const deleted = CoacheCacheData?.filter(({ node }) =>
              node.id === result?.data?.CoachingConversationSubscription?.coachingConversation?.id
                ? false
                : true)
            if (mountedRef.current) {
              setallCoachingConversation(deleted) 
            }
            break
          default:
            break
        }

      }
    })
  }
  React.useEffect(() => {
    if (coachingPermitted?.required_permission && me) {
      coachingConversation({ })
    }
  }, [coachingPermitted, me])


  React.useEffect(() => {
    if (me && userPermission) {
      let permission = globalPermissionValidator(coaching_engagement_permission, userPermission)
      setCoachingPermitted(permission)
    }
  }, [me, userPermission])

  return (
    <>
      {
        !permissionLoader &&
        <div>
          {
            coachingPermitted?.required_permission ?
              <GrowHomePage
                {...props}
                allCoachingConversation={allCoachingConversation}
                loading={loading}
                coachingConversation={(filter) => coachingConversation({ ...filter })}
                grow_page_info={grow_page_info}></GrowHomePage>
              :
              <NoPermissionView />
          }
        </div>
      }
    </>
  );

};

export default compose(withApollo)(GrowHomeContainer);
