import React from "react";
import MyProfileHomeView from "../components/my-profile-home-view";

const MyProfileHomeContainer =(props)=>{

    return(
        <>
        <MyProfileHomeView {...props}/>
        </>
    )
}

export default MyProfileHomeContainer;