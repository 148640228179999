import { MetaTags } from "modules/look";
import React from "react";
import MyProfileContainer from "../containers/my-profile-container";
import NotificationSettingsContainer from "../containers/notification-settings-container";
import ChangePasswordContainer from "../containers/change-password.container";
import { icons } from "antd/lib/image/PreviewGroup";
import { profile_icons } from "../icons";

const MyProfileHomeView = (props) => {
    const [tab, setSideBarTab] = React.useState([
        {
            key: 'my_profile',
            label: 'My Profile',
            inactive_icon:profile_icons.my_profile_inactive,
            active_icon: profile_icons.my_profile_active
        },
        // {
        //     key: 'notification_settings',
        //     label: 'Notifications Settings',
        //     inactive_icon:profile_icons.notification_inactive,
        //     active_icon: profile_icons.notification_active
        // },
        {
            key: 'password',
            label: 'Password',
            inactive_icon:profile_icons.change_password_inactive,
            active_icon: profile_icons.change_password_active
        },
    ])
    const [current_tab, SetCurrentTab] = React.useState(
        {
            key: 'my_profile',
            label: 'My Profile',
            inactive_icon:profile_icons.my_profile_inactive,
            active_icon: profile_icons.my_profile_active
        }
    )

    const content = {
        my_profile: MyProfileContainer,
        notification_settings: NotificationSettingsContainer,
        password:ChangePasswordContainer
    }



    const CurrentView = content[current_tab?.key]
    return (
        <div>
            <MetaTags title="Employee profile" description="Employee profile" />
            <div className={current_tab?.key=='my_profile'?"":'profile-card'}   style={{ marginTop: "1.5em" }}>
                <div className='profile-tab-view' style={{background:current_tab?.key=='my_profile'?"#FFFFFF":"",borderRadius:current_tab?.key=='my_profile'?"0.75em 0.75em 0 0":""}}>
                    {
                        tab?.map(tab => (

                            <div className={`profile-tab-normal ${(current_tab?.key === tab?.key || (current_tab?.key?.includes(tab?.key))) ? 'profile-tab-active' : ''}`} onClick={() => SetCurrentTab(tab)} style={{display:'flex',flexDirection:"row",justifyContent:'center',alignItems:'center',gap:'1em'}}>
                                <img src={current_tab?.key === tab?.key?current_tab?.active_icon:tab.inactive_icon} alt="icon" />
                                <h4 className={`profile-tab-label ${(current_tab?.key === tab?.key || (current_tab?.key?.includes(tab?.key))) ? 'profile-tab-active-label' : ''}`} style={{color:current_tab?.key === tab?.key?'#4CCB1F':'#000'}}>{tab?.label}</h4>
                            </div>
                        ))
                    }
                    {/* <div className="profile-tab-normal" style={{display:'flex',flexDirection:"row",justifyContent:'start',alignItems:'center',gap:'1em',paddingLeft:"2em"}}>
                        <img src={profile_icons?.delete_account} alt="delete_account" />
                        <h4 className="profile-tab-label" style={{color:"#F95F53"}}>Delete Account</h4>
                    </div> */}
                    <div className='border-line'>

                    </div>
                </div>
                <div className={current_tab?.key=='my_profile'?"":'user-content-container'}>

                    {
                        content[current_tab?.key] && (

                            <CurrentView current_tab={current_tab} {...props} />
                        )
                    }
                </div>
            </div>
        </div>
    )

}
export default MyProfileHomeView